import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from "react-i18next";

import './App.scss';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import Login from './screens/Login';
import { has_permission, isLoggedIn } from './helper/functions/auth';
import Loading from './helper/screens/Loading';
import UserUnit from './communicator/http/User';

import { BASENAME } from './spectory-config';
import Dashboard from './screens/Dashboard';
import PasswordForgot from './screens/PasswordForgot';
import PasswordReset from './screens/PasswordReset';

import { changeLanguageHandler } from './helper/functions/multilingual';
import ChangePassword from './screens/ChangePassword';

const App = () => {

    const [loading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);
    const [startPage, setStartPage] = useState('login');

    //Creating a method to change the language onChnage from select box
    const userUnit = new UserUnit();


    useEffect(() => {
        changeLanguageHandler();

        isLoggedIn().then((res) => {

            if (res.acceptedGdpr === false) {
                setStartPage('gdpr');
                setLoading(false);
            } else if (res.changedInitPassword === false) {
                setStartPage('changePassword');
                setLoading(false);
            } else {
                setLoggedIn(true);
                setLoading(false);
            }

        }).catch(() => {
            localStorage.removeItem('token');
            // window.location.reload();
            setLoggedIn(false);
            setLoading(false);
        })

    }, []);

    if (loading) {
        return <Loading visible={loading} />;
    }

    if (loggedIn) {

        return (
            <Router basename={BASENAME}>
                <div className="">
                    <div className="content_wrapper">
                        <Routes>
                            <Route path='/' element={<Dashboard />} />
                            <Route path='/change-password' element={<ChangePassword />} />
                            {/* <Route exact path='/' render={(props) => {
                                        return (<AdminUsers {...props} com={this.com} set_currentPage={this.set_currentPage} />)
                                    }} />
                                    <Route exact path='/user/:id' render={(props) => {
                                        return (<AdminUsersEdit {...props} com={this.com} set_currentPage={this.set_currentPage} />)
                                    }} /> */}

                        </Routes>
                    </div>
                </div>
            </Router>
        );

    } else {



        return (
            <Router basename={BASENAME}>
                <Routes>
                    <Route path='/forgot-password' element={<PasswordForgot />} />
                    <Route path='/reset-password/:key/:secret' element={<PasswordReset />} />
                    <Route path='*' element={<Login startPage={startPage} />} />
                </Routes>
            </Router>
        );
    }
}

export default App;