import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import icon from '../../assets/icons/menu_bullets.png';

import styles from './style.module.scss';

const ToolTip = ({ items }) => {

    const [open, setOpen] = useState(false);

    return <div className={styles.container}>
        <div className={styles.button} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}></div>
        <div className={`${styles.content} ${open ? styles.show : undefined}`}>
            {
                items.map(item => {
                    return item.to !== undefined ? <Link to={item.to} className={styles.item}>{item.label}</Link> : <div onClick={item.do} className={styles.item}>{item.label}</div>
                })
            }
        </div>
    </div>


}

export default ToolTip;