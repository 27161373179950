import { FormControl, InputLabel, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import UserUnit from '../../communicator/http/User';
import Loading from '../../helper/screens/Loading';
import Notification from '../../helper/screens/Notification';
import { validation } from '../../helper/validation';
import { useForm, Controller as FormController } from "react-hook-form";
import Message from '../../elements/message';
import { translate } from '../../helper/functions/multilingual';
import { Link } from 'react-router-dom';
import Popup from '../../elements/popup';
import { Col, Container, Row } from 'react-bootstrap';

const ChangePassword = () => {

    const { register, handleSubmit, watch, control, formState: { errors } } = useForm();

    const [loading, setLoading] = useState(false);

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");

    let userUnit = new UserUnit();

    const doChangePassword = (data) => {
        setLoading(true);

        const cbSuccess = (res) => {
            setLoading(false);
            setErrorMessageTitle(translate('change_password_success_title'));
            setErrorMessageBody(translate('change_password_success_body'));
            setErrorMessageButtonText("OK");
            setErrorMessageType("success");
            setErrorMessageOpen(true);
        }

        const cbError = (err) => {
            console.log(err);
            setLoading(false);
            setErrorMessageTitle(translate('500_title'));
            setErrorMessageBody(translate('500_body'));
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
        }

        userUnit.update({ password: data.password }, cbSuccess, cbError);
    }


    return (
        <>
            <div className="login_wrapper">
                <div className="login_container"
                >
                    <Link to="/">{translate('back')}</Link>
                    <br />
                    <br />
                    <h2 style={{}}>{translate('change_password')}</h2>
                    <form onSubmit={handleSubmit(doChangePassword)}>
                        <TextField
                            {...register('password', { required: true })}
                            error={errors.password}
                            id="standard-basic"
                            label={translate('password')}
                            type="password"
                        />
                        <input type="submit" style={{ width: '100%' }} className="button" value={translate('change_password')} />
                    </form>
                </div>
            </div>
            <Container className="footer" fluid>
                <Row style={{ marginTop: 0 }}>
                    <Col md={12}>
                        <a target="_blank" href="https://3garbeitsplatz.com/datenschutz">{translate('gdpr')}</a>
                        <a target="_blank" href="https://3garbeitsplatz.com/impressum">{translate('imprint')}</a>
                    </Col>
                </Row>
            </Container>

            <Message
                open={errorMessageOpen}
                type={errorMessageType}
                title={errorMessageTitle}
                body={errorMessageBody}
                buttonText={errorMessageButtonText}
                buttonAction={() => setErrorMessageOpen(false)}
            />
            <Loading visible={loading} />

        </>
    );
}

export default ChangePassword;