import { FormControl, InputLabel, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import UserUnit from '../../communicator/http/User';
import Loading from '../../helper/screens/Loading';
import Notification from '../../helper/screens/Notification';
import { validation } from '../../helper/validation';
import { useForm, Controller as FormController } from "react-hook-form";
import Message from '../../elements/message';
import { translate } from '../../helper/functions/multilingual';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Login from '../Login';


const PasswordReset = (props) => {


    const params = useParams();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, watch, control, formState: { errors } } = useForm();

    const userUnit = new UserUnit();

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessageType, setErrorMessageType] = useState('error');
    const [errorMessageTitle, setErrorMessageTitle] = useState("");
    const [errorMessageBody, setErrorMessageBody] = useState("");
    const [errorMessageButtonText, setErrorMessageButtonText] = useState("OK");

    if (params.key === undefined || params.secret === undefined) {
        return <Login />;
    }

    const doResetPassword = (data) => {

        setLoading(true);

        let cbSuccess = (res) => {

            setLoading(false);
            setErrorMessageTitle(translate('reset_password_success_title'));
            setErrorMessageBody(translate('reset_password_success_body'));
            setErrorMessageButtonText("OK");
            setErrorMessageType("success");
            setErrorMessageOpen(true);
        }

        let cbError = (err) => {
            setLoading(false);

            setErrorMessageTitle(translate('login_error'));
            setErrorMessageBody(translate('login_error_body'));
            setErrorMessageButtonText("OK");
            setErrorMessageType("error");
            setErrorMessageOpen(true);
        }

        userUnit.resetPassword(data.password, params.key, params.secret, cbSuccess, cbError);

    }

    return (
        <>
            <div className="login_wrapper">
                <div className="login_container">
                    <Link to="/">{translate('back_to_login')}</Link>
                    <h1>{translate('reset_password')}</h1>
                    <br />
                    <form onSubmit={handleSubmit(doResetPassword)}>
                        <TextField
                            {...register('password', { required: true })}
                            error={errors.email}
                            id="standard-basic"
                            label={translate('password')}
                            type="password"
                        />
                        <input type="submit" style={{ width: '100%' }} className="button" value={translate('reset_password')} />
                    </form>
                </div>

            </div>
            <Loading visible={loading} />
            <Message
                open={errorMessageOpen}
                type={errorMessageType}
                title={errorMessageTitle}
                body={errorMessageBody}
                buttonText={errorMessageButtonText}
                buttonAction={() => {
                    if (errorMessageType === 'success') {
                        navigate('/');
                    } else {
                        setErrorMessageOpen(false)
                    }
                }}
            />
        </>
    );
}

export default PasswordReset;