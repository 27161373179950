import React, { useState, useEffect } from 'react';
import { translate } from '../../helper/functions/multilingual';
import styles from './style.module.scss';
import { Link } from 'react-router-dom'

const BurgerMenu = () => {

    const [isOpen, setIsOpen] = useState(false);

    return <>
        <div className={styles.burger_right_wrapper}><div
            className={styles.burger_right}
            onClick={() => setIsOpen(prev => !prev)}
        ></div>
            <div className={styles.content}>
                <Link className="small_button" to="/change-password">{translate('change_password')}</Link>
                <div className="small_button" onClick={() => {
                    localStorage.removeItem('token');
                    window.location.reload();
                }}>{translate('logout')}</div>
            </div>
        </div>
    </>

}

export default BurgerMenu;